<template>
    <div style="padding:40px">
        <div style="display:flex;margin-bottom: 20px;">
            <el-input placeholder="请输入客户姓名" v-model="query.crmName"  class="inPut"></el-input>
            <el-input placeholder="请输入发起联系人姓名" v-model="query.relationName"  class="inPut"></el-input>
            <el-date-picker
                v-model="v1"
                class="inPut"
                type="daterange"
                range-separator="至"
                @change="disposeTime"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :clearable="false"
                >
            </el-date-picker>
            <el-button type="primary" @click="getData(0)">搜索</el-button>
        </div>
        <el-table :data="orderList" border>
            <el-table-column label="意向客户表Id" prop="crmCustId" min-width="100"></el-table-column>
                <el-table-column label="联系内容" prop="relationMatter"></el-table-column>
                <el-table-column label="发起联系人姓名" prop="relationName" min-width="100" />
                <el-table-column label="其他跟进要点说明" prop="remark" min-width="130" />
                <el-table-column label="录音路径" ></el-table-column>
                <el-table-column label="本次联系人">
                    <template #default="scope">
                        <el-popover placement="right" :width="500" trigger="click">
                            <template #reference>
                                <el-button size="small" style="margin-right: 16px">查看</el-button>
                            </template>
                            <div style="padding:20px" v-if="scope.row.crmCustRelation">
                            <div style="font-size:24px">本次联系人信息：</div> 
                                <div class="flex_mar">
                                <div>本次联系人姓名：{{ scope.row.crmCustRelation.name }}</div>
                                <div>本次联系人电话：{{ scope.row.crmCustRelation.phone }}</div>
                                </div>
                                <div class="flex_mar">
                                    <div>创建时间：{{ scope.row.crmCustRelation.createTime }}</div>
                                </div>
                                <div class="flex_mar">
                                    其他跟进要点说明：{{ scope.row.crmCustRelation.remark }}
                                </div>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="联系时间" prop="relationTime" min-width="130" />
        </el-table>
        <div class="pagination">
            <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :current-size="query.pageSize"
            :total="total"
            @current-change="handlePageChange"
            ></el-pagination> 
        </div>
    </div>
</template>
<script>
import {pageListByQuery} from "@/api/attract"
export default{ 
    data(){ 
        return{
            v1:[],
            query:{
                crmName:'',
                endTime:'',
                pageIndex:0,
                pageSize:10,
                relationName:'',
                startTime:''
            },
            orderList:[],
            total:null,
        }
    },
    methods:{
        getData(index){
          if (index!=null){
            this.query.pageIndex = index;
          }
            pageListByQuery(this.query).then(res=>{
                if(res.code == 200){ 
                    this.orderList = res.data.records
                    this.total = res.data.total
                }else{
                    this.$message.error('请求失败！')
                }
            })
        },
        handlePageChange(e){
          console.log("分页点击事件")
            this.query.pageIndex = e
            this.getData()
        },
        disposeTime(){
        if(this.v1.length>=2){
          this.query.startTime = this.dateFormat(this.v1[0]);
          this.query.endTime = this.dateFormat(this.v1[1]);
        }
        },
        dateFormat(val) {
            var date = new Date(val);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            var d = date.getDate();
            d = d < 10 ? "0" + d : d;
            const time = parseInt(y) + "-" + parseInt(m) + "-" + parseInt(d);
            return time;
        },
    },  
    created(){
        this.getData()
    },
}

</script>
<style>
.inPut{
    margin: 0 10px;
    width:260px
}
.flex_mar{ 
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
}
</style>